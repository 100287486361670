<template>
    <div class="full-height-layout fhf pb-10">
        <mercur-card class="ml-0 mr-0 fhf u-no-horizontal-overflow">
            <h2 class="font-weight-normal">Basic artwork check: {{ configuration.missingBasicArtworkConfigurationAmount }} configurations of {{ configuration.productConfigurationName }}</h2>
            <table class="mt-20 artwork-management-table">
                <tr class="artwork-management-table__header">
                    <th class="artwork-management-table__header-cell w-percentage-55">
                        INTERNAL ARTWORK NAME
                    </th>
                    <th class="artwork-management-table__header-cell artwork-management-table__header-cell--normal-weight">
                        MANUAL ARTWORK TEMPLATE
                    </th>
                </tr>
                <tr class="filters-row">
                    <td class="filters-row__cell">
                        <div class="artwork-management-table__cell-content-centered">
                            <mercur-input class="w-100 mb-0" v-model="artworkNameSearch">Search name</mercur-input>
                            <i class="fas fa-search artwork-management-icon-orange artwork-management-search-icon mt-3 ml-2"></i>
                        </div>
                    </td>
                    <td class="filters-row__cell">
                        <div class="artwork-management-table__cell-content-centered">
                            <mercur-select class="w-100 my-2" v-model="selectedArtworkTemplateAll">
                                <template slot="label">Select an artwork template</template>
                                <option v-for="template in artworkTemplateFiles" :value="template" :key="template"> {{ template }}</option>
                            </mercur-select>
                            <mercur-button
                                @click.native="applyTemplateToAll"
                                :disabled="isSavingArtworkConfigurationSettingsByInternalArtworkName || !selectedArtworkTemplateAll"
                                class="btn btn-icon artwork-management-btn-icon">
                                <i class="fas fa-arrow-alt-circle-down artwork-management-icon-orange artwork-management-apply-icon"></i>
                            </mercur-button>
                        </div>
                    </td>
                </tr>
                <tr class="artwork-management-table__row" v-for="item in filteredItems" :key="item.internalArtworkName">
                    <td class="artwork-management-table__cell text-artwork-blue">{{ item.internalArtworkName }}</td>
                    <td class="artwork-management-table__cell">
                        <mercur-select v-model="item.manualArtworkTemplate">
                            <template slot="label">Select an artwork template</template>
                            <option v-for="(template, index) in artworkTemplateFiles" :value="template" :key="index">{{ template }}</option>
                        </mercur-select>
                    </td>
                </tr>
            </table>
        </mercur-card>
        <mercur-card class="mt-15 ml-0 mr-0 text-right">
            <mercur-button
                    :disabled="isSavingConfiguration || !!promises.length"
                    @click.prevent="goToNextStep"
                    class="btn btn-raised btn-yellow text-uppercase">
                <span>Save + Continue</span>
                <i class="fas fa-forward artwork-management-save-icon ml-1"></i>
            </mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import ArtworkManagementConfigurationsMixin from '@/views/artwork_management/configurations/ArtworkManagementConfigurationsMixin'

export default {
    name: 'BasicArtworkConfig',
    mixins: [ ArtworkManagementConfigurationsMixin ],
    data () {
        return {
            items: null,
            artworkNameSearch: '',
            selectedArtworkTemplateAll: null,
            promises: [],
        }
    },
    methods: {
        applyTemplateToAll () {
            this.filteredItems.forEach(item => {
                const index = this.configurationSettings.findIndex(e => e.internalArtworkName === item.internalArtworkName)
                this.$set(this.configurationSettings[index], 'manualArtworkTemplate', JSON.parse(JSON.stringify(this.selectedArtworkTemplateAll)))
            })

            if (this.selectedArtworkTemplateAll === null) {
                return
            }

            const internalArtworkNames = this.filteredItems.map(item => item.internalArtworkName)
            const payload = { internalArtworkNames, data: { manualArtworkTemplate: this.selectedArtworkTemplateAll } }
            this.saveArtworkConfigurationSettingsByInternalArtworkName(payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Configuration settings were updated',
                    type: 'success',
                })
            })
        },
        goToNextStep () {
            this.configurationSettings.forEach((setting, index) => {
                if (setting.manualArtworkTemplate !== this.configurationSettingsInitialValues[index].manualArtworkTemplate) {
                    this.promises.push(this.saveArtworkConfigurationSetting(setting))
                }
            })

            Promise.all(this.promises).then(() => {
                this.$router.push({
                    name: 'ArtworkManagementManualCheckConfig',
                    params: {
                        ...this.params,
                    },
                })
            })
        },
    },
    computed: {
        filteredItems () {
            if (!this.configurationSettings) {
                return []
            }

            return this.configurationSettings.filter(item => item.internalArtworkName.includes(this.artworkNameSearch))
        },
    },
    created () {
        this.listArtworkTemplateFiles()
    },
}
</script>
